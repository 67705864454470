import styled from 'styled-components';
import { colors } from '../colors';
import TickIcon from '../../assets/images/icons/tickicon.svg';

const Checkbox = styled.div`
	display: inline-block;
	> input {
		opacity: 0;
		width: 0px;
		height: 0px;
		display: none;
	}
	> input + label {
		position: relative;
		padding-left: 25px;
		cursor: pointer;
		font-size: 14px;
		padding-top: 2px;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 1px;
			width: 20px;
			height: 20px;
			border: 1px solid ${colors.BlueBase};
			background: ${colors.White};
			border-radius: 4px;
		}
		&:after {
			content: url(${TickIcon});
			position: absolute;
			top: 2px;
			left: 4px;
			font-size: 12px;
			width: 12px;
			height: 10px;
			color: ${colors.White};
			transition: all 0.2s;
		}
	}
	> input:not(:checked) + label {
		&:after {
			opacity: 0;
			transform: scale(0);
		}
	}

	> input:checked + label {
		&:after {
			opacity: 1;
			transform: scale(1);
		}
		&:before {
			background: ${colors.BlueBase};
			border: 1px solid ${colors.BlueBase};
		}
	}
	> input:disabled + label {
		pointer-events: none;
		&:after {
			opacity: 0;
			pointer-events: none;
		}
		&:before {
			background: ${colors.BlueBase};
			pointer-events: none;
		}
	}
	> input:disabled {
		pointer-events: none;
	}
	> input:checked:focus + label,
	input:not(:checked):focus + label {
		&:before {
			border: 1px solid ${colors.BlueBase};
			background: ${colors.white};
		}
	}

	${({ specialtySelect }) =>
		specialtySelect &&
		`margin-bottom:16px;
            label{
                font-size: 14px !important;
                line-height: 20px !important;
                color: ${colors.BlueBase};
                padding-left: 35px !important;
                padding-top: 2px !important;
            }
	`}
	${({ markAsHoliday }) =>
		markAsHoliday &&
		`float:right;
		margin-bottom: 0;
		label{
			padding-left: 29px!important;
		}
	`}
	${({ patientNotAvailable }) =>
		patientNotAvailable &&
		`width:100%;
		border: 1px solid ${colors.GreyED};
		box-sizing: border-box;
		border-radius: 4px;
		padding:10px;
        margin-top:8px;
        display:inline-flex !important;
		label{
			font-size: 14px!important;
			font-family: 'RobotoMedium'!important;
			color: ${colors.Grey53};
			padding-left: 30px!important;
			padding-top: 2px!important;
		}
		:hover{
			border: 1px solid ${colors.BlueE6};
			background: ${colors.BlueE6};
			label{
				color:${colors.BlueBase};
			}
		}
	`}
	${({ patientNotAvailableActive }) =>
		patientNotAvailableActive &&
		`border: 1px solid ${colors.BlueE6};
		background: ${colors.BlueE6};
		label{
			color:${colors.BlueBase};
		}
	`}
	${({ patientNotAvailableSummary }) =>
		patientNotAvailableSummary &&
		`width:100%;
		border: 1px solid ${colors.GreyED};
		box-sizing: border-box;
		border-radius: 4px;
		padding:10px;
        margin-top:8px;
        display: inline-flex !important;
		label{
			font-size: 14px!important;
			font-family: 'RobotoMedium'!important;
			color: ${colors.Grey53};
			padding-left: 30px!important;
			padding-top: 2px!important;
			line-height:20px;
		}
		input{
			height:auto!important;
		}
		:hover{
			border: 1px solid ${colors.BlueE6};
			background: ${colors.BlueE6};
			label{
				color:${colors.BlueBase};
			}
		}
	`}
	${({ rememberMe }) =>
		rememberMe &&
		`width:100%;
		border: 1px solid ${colors.White};
		box-sizing: border-box;
		border-radius: 4px;
		padding:0;
		margin-top:23px;
		label{
			font-size: 13px!important;
			line-height: 20px!important;
			color: ${colors.VioletDark};
			padding-left: 30px!important;
			padding-top: 2px!important;
			@media (max-width:530px){
				padding-left: 18px!important;
			}
		}
		:hover{
			border: 1px solid ${colors.White};
			background: ${colors.White};
			label{
				color:${colors.VioletDark};
			}
		}
	`}
	${({ CallFailedNotAvailable }) =>
		CallFailedNotAvailable &&
		`width:auto;
		border:none;
		box-sizing: border-box;
		border-radius: 0;
		padding:0;
		margin:0 auto 24px;
		label{
			font-size: 14px!important;
			font-family: 'RobotoMedium'!important;
			color: ${colors.BlueBase};
			padding-left: 30px!important;
			padding-top: 2px!important;
		}
		:hover{
			border:none;
			background: ${colors.White};
			label{
				color:${colors.BlueBase};
			}
		}
	`}
	${({ activeState }) =>
		activeState &&
		`border: 1px solid ${colors.BlueE6};
		background: ${colors.BlueE6};
		label{
			color:${colors.BlueBase};
		}
	`}
`;

export default Checkbox;
