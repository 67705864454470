import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import avatar from '../../assets/images/avatar.png';
// import SupportIcon from '../../stories/assets/images/icons/support.svg';

import {
	GreetingMessage,
	dateFormatter,
	//  getBrowserInfo
} from '../../helpers/Utilities';
import { dateSeparatorConfig, Roles, doctorLabel } from '../../helpers/Constants';

import SidebarItem from './SideBarItem';
import AppSpinner from '../Spinner';
import {
	Header,
	HeaderLogo,
	HeaderRight,
	HeaderInfo,
	Icon,
	PageWrapper,
	InnerWrapper,
	Dropdown,
	DropdownList,
	// DropdownListSepertor,
	Avatar,
} from '../../stories';
import { getLocalStorageValueAsJSON } from '../../pages/Login/login.store';

function AppLayout(props) {
	const { children, appLogout = () => {}, layoutProps = {} } = props;
	const { innerWrapper = {} } = layoutProps;
	const user = getLocalStorageValueAsJSON('userDetails');

	const userDetails = useSelector(({ AuthStore }) => {
		const { userDetails = {} } = AuthStore;
		return userDetails;
	});

	const { role = '', name, friendlyName, profile = avatar, showLoader = false } = userDetails;

	if (!user || (user && !user.role) || (user && user.role && user.role.length === 0)) {
		localStorage.clear();
		window.location.href = '/';
	}
	// const viewProfile = [Roles.doctor, Roles.coach, Roles.clinicAdmin];
	const greetings = GreetingMessage();
	const profileName =
		role === Roles.doctor
			? `${doctorLabel}${name || friendlyName || (user && user.name) || ''}`
			: `${name || friendlyName || (user && user.name) || ''}`;

	// const goToSupportMail = async () => {
	// 	const browserInfo = await getBrowserInfo();
	// 	const subject = 'WeCare Dashboard: Web Help %26 Support';
	// 	let body = '%0A%0A%0A%0A';
	// 	body += '------------------------- Write above this line --------------------------------%0A';
	// 	body += `%0A Account:  ${user.name}`;
	// 	body += `%0A Browser name: ${browserInfo.name}`;
	// 	body += `%0A OS: ${browserInfo.os}`;
	// 	body += `%0A Version: ${browserInfo.version}`;
	// 	window.open(`mailto:${'support@joinwecare.com'}?body=${body}&subject=${subject}`);
	// };

	return (
		<PageWrapper>
			{' '}
			{/* Sidebar */}{' '}
			{/* <Sidebar open={false}>
							<SidebarItem />
						</Sidebar> */}{' '}
			{showLoader && <AppSpinner />}{' '}
			<InnerWrapper {...innerWrapper}>
				{' '}
				{/* Header */}{' '}
				<Header>
					<div
						className=" mx-auto flex items-center justify-between w-full nav"
						style={{
							maxWidth: '85%',
						}}
					>
						<Link to="/patient-management">
							<HeaderLogo />
						</Link>{' '}
						<div className="flex items-center justify-center bg-white rounded-3xl overflow-hidden toggle-menu">
							<SidebarItem />
						</div>{' '}
						<HeaderRight>
							<HeaderInfo>
								<section
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'start',
									}}
								>
									<img
										style={{
											margin: '0 5px',
										}}
										src={greetings.image}
										alt={greetings.message}
									/>{' '}
									<h1 className="text-white">
										{' '}
										{greetings.message}, <span className="font-bold"> {profileName} </span>{' '}
									</h1>{' '}
								</section>{' '}
								<p className="text-white">
									{' '}
									{dateFormatter(Date.now(), dateSeparatorConfig.L_D_M_Y, true)}{' '}
								</p>{' '}
							</HeaderInfo>{' '}
							<Dropdown
								open={false}
								name={
									<Avatar small rounded>
										<img src={profile || avatar} alt="User Avatar" />
									</Avatar>
								}
							>
								{/* {viewProfile.includes(role) && (
											<DropdownList>
												<Link to="/profile">
													<Icon iconName="icon-Edit" /> Edit profile
												</Link>
											</DropdownList>
										)} */}
								{/* <DropdownListSepertor /> */}{' '}
								{/* <DropdownList onClick={() => goToSupportMail()}>
											<Icon iconName="icon-support-1" />
											Help and support
										</DropdownList> */}{' '}
								<DropdownList onClick={appLogout}>
									<Icon
										iconName="icon-Logout"
										style={{
											fontSize: '14px',
											marginLeft: '3px',
										}}
									/>
									Logout{' '}
								</DropdownList>{' '}
							</Dropdown>{' '}
						</HeaderRight>{' '}
					</div>{' '}
				</Header>
				{children}{' '}
			</InnerWrapper>{' '}
		</PageWrapper>
	);
}

export default memo(AppLayout);
