import styled from 'styled-components';

const InnerWrapper = styled.section`
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: auto;
	max-height: calc(100vh - 62px);
	/* background: #fdfdfd; */
	scrollbar-color: #c4c4c4;
	scrollbar-width: thin;
	${({ fixedFooterBlock }) =>
		fixedFooterBlock &&
		`min-height: auto;
        max-height: calc(100vh - 122px);
        padding-bottom:35px;
        a{
            text-decoration:none;
            padding-bottom:1em;
        }
        a button{
            width:100%;
        }
	`}
	${({ fixedFooterBlockSmall }) =>
		fixedFooterBlockSmall &&
		`min-height: auto;
		max-height: calc(100vh - 80px);
        a{
            text-decoration:none;
            padding-bottom:1em;
        }
        a button{
            width:100%;
        }
	`}
	padding-bottom: ${(props) => props.paddingBottom};
`;

export default InnerWrapper;
