import styled from 'styled-components';
import { colors } from '../colors';
import DayImage from '../../assets/images/activityJournalIcon/dayImg.svg';
import DayImageActive from '../../assets/images/activityJournalIcon/dayImageActive.svg';
import DietDayActive from '../../assets/images/activityJournalIcon/dietDayImageActive.svg';
import MedicationDayActive from '../../assets/images/activityJournalIcon/medicationDayImageActive.svg';
import WaterDayActive from '../../assets/images/activityJournalIcon/waterDayImageActive.svg';
import MoodsDayActive from '../../assets/images/activityJournalIcon/moodsDayImageActive.svg';
import SleepDayActive from '../../assets/images/activityJournalIcon/sleepDayImageActive.svg';
import Row from '../layout/row';
// eslint-disable-next-line import/no-cycle
import { Col } from '../..';
import { fontMedium } from '../../assets/styles/typography';

const Cards = styled.div`
	display: inline-block;
	width: 100%;
	background: ${colors.White};
	border-radius: 4px;
	border: 1px solid ${colors.Border2};
	${({ exerciseCard }) =>
		exerciseCard &&
		`background:${colors.White};
        cursor: pointer;
        border:none!important;
        	border-radius: 0!important;

        box-sizing: border-box;
        box-shadow: none;
        cursor: default;
        padding:0;
        .cardHeader{
            border: none !important;
            background: #D7F5E6;
            padding:20px 16px;
            display: flex;
            align-items:Center;
            border-radius: 8px 8px 0 0;
            div{
                width:48px;
                height:48px;
                margin-right:12px;
                img{
                    width: 48px!important;
                    height: 48px!important;
                    max-width: 48px!important;
                }
                + div{
                    width: auto;
                    height: auto;
                    margin-right: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                }
            }
            h1{
                font-family: ${fontMedium.familyName};!important;
                font-size: 16px;
                color: #284744;
                overflow: hidden;
                img {
                    width: 122px !important;
                     height: auto !important;
                    max-width: 22px !important;
                    +span +img {
                        margin-top:-4px;
                    }
                }
                span {
                   padding: 0 6px 0 8px;
                font-size: 22px;
                font-family: ${fontMedium.familyName};!important;
                margin-top: -10px;
                display: block;
                }
            }
            p{
                font-size: 14px;
                color: #284744;
                margin:2px 0 0 0;
                padding:0;
            }
        }
        .card-body{
            border: 1px solid #E5E7E9;
            border-top: 0;
            box-sizing: border-box;
            padding:16px;
            border-radius:0 0 8px 8px ;
            display: flex;
            align-items: center;
            justify-content: center;
            background:white;
            .dayList{
                width:32px;
                max-width:32px;
                flex:0 0 32px;
                margin:0 10px;
                display: flex;
                align-items:Center;
                min-height: 60px;
                position: relative;
                justify-content: center;
                &:before{
                    content:"";
                    background: url(${DayImage}) no-repeat;
                    width:32px;
                    max-width:32px;
                    flex:0 0 32px;
                    height:32px;
                    position: absolute;
                    top: 0;
                }
                h1{
                    font-size: 16px;
                    color: #E6E5E5;
                    display:flex;
                    justify-content: center;
                    position: absolute;
                    bottom: 0;
                    width:22px;
                    height:22px;
                    align-items:flex-end;
                }
                &.active {
                    &:before{
                        background: url(${DayImageActive}) no-repeat;
                    }
                    h1{
                        color: #284744;
                    }
                }
                &.activeSunday{
                    h1{
                        background: #EEEEEF;
                        border-radius: 50%;
                        color: #284744;
                    }
                }

            }
        }
    `};
	${({ attachmentCardInfoPopup }) =>
		attachmentCardInfoPopup &&
		`position: absolute;
		top: 15px;
		left: 20px;
		padding: 0;
		border: none;
		}
	`};
	${({ attachmentCard }) =>
		attachmentCard &&
		`padding:10px 18px 10px 10px;
		// width: calc(33.333% - 10px);
		float:left;
		cursor: pointer;
		label,p{
			cursor: pointer;
		}
		:hover, :focus{
			border: 1px solid ${colors.GreyDC};
			background:${colors.BlueEC};
			box-shadow: 1px 1px 10px rgba(50, 99, 214, 0.15);
		}
		${Row}{
			flex-wrap: nowrap;
		}
		label{
			word-break: break-word;
			padding-right:10px;
		}
		@media screen and (max-width: 767px) {
			width: calc(50% - 10px);
		}
		@media screen and (max-width: 592px) {
			width:100%;
			margin-right:0!Important;
			${Row}{
				flex-wrap: nowrap;
				${Col}{
					width: auto!important;
				}
			}
		}
	`};

	${({ attachmentCardClose }) =>
		attachmentCardClose &&
		`padding:10px 18px 10px 10px;
		// width: calc(33.333% - 10px);
		float:left;
		margin:0 10px 10px 0;
		${Row}{
			flex-wrap: nowrap;
		}
		@media screen and (max-width: 767px) {
			width: calc(50% - 10px);
		}
		@media screen and (max-width: 592px) {
			width:100%;
			margin-right:0!Important;
			${Row}{
				flex-wrap: nowrap;
				${Col}{
					width: auto!important;
				}
			}
		}
	`};

	${({ attachmentCardAppointment }) =>
		attachmentCardAppointment &&
		`padding:10px 18px 10px 10px;
		width: calc(33.5% - 10px);
		float:left;
		${Row}{
			flex-wrap: nowrap;
		}
		label{
			white-space: normal;
			word-break: break-all;
			padding-right: 5px;
		}
		@media screen and (max-width: 1440px) {
			width: calc(50% - 10px);
		}
		@media screen and (max-width: 592px) {
			width:100%;
			margin-right:0!Important;
			${Row}{
				flex-wrap: nowrap;
				${Col}{
					width: auto!important;
				}
			}
		}
	`};
	${({ appointmentAttachmentCard }) =>
		appointmentAttachmentCard &&
		`padding:10px 18px 10px 10px;
		width: 228px;
		float:left;
		label{
			white-space: normal;
			word-break: break-all;
			padding-right: 5px;
		}
		${Row}{
			flex-wrap: nowrap;
		}
		@media screen and (max-width: 767px) {
			width: calc(50% - 10px);
		}
		@media screen and (max-width: 592px) {
			width:100%;
			margin-right:0!Important;
			${Row}{
				flex-wrap: nowrap;
				${Col}{
					width: auto!important;
				}
			}
		}
	`};
	${({ summaryAttachmentCard }) =>
		summaryAttachmentCard &&
		`padding:10px 18px 10px 10px;
		width:calc(33.7% - 8px);
		float:left;
		label{
			white-space: normal;
			word-break: break-word;
		}
		${Row}{
			align-items: flex-start;
		}
		@media screen and (max-width: 640px) {
			width:100%;
			margin-right:0!Important;
			${Row}{
				flex-wrap: nowrap;
				${Col}{
					width: auto!important;
				}
			}
		}
	`};
	${({ attachmentCardInfoPopup }) =>
		attachmentCardInfoPopup &&
		`position: absolute;
		top: 15px;
		left: 20px;
		padding: 0;
		border: none;
		}
	`};
	${({ dietCard }) =>
		dietCard &&
		`.cardHeader{
            background: #FFEBD1;
            h1{
                color: #5F4728;
            }
            p{
                color: #5F4728;
            }
        }
        .card-body{
            .dayList{
                &.active {
                    &:before{
                        background: url(${DietDayActive}) no-repeat;
                    }
                    h1{
                        color: #5F4728;
                    }
                }
                &.activeSunday{
                    h1{
                        color: #5F4728;
                    }
                }

            }
        }
    `};
	${({ medicationCard }) =>
		medicationCard &&
		`.cardHeader{
            background: #EAE5F9;
            h1{
                color: #372C65;
            }
            p{
                color: #372C65;
            }
        }
        .card-body{
            .dayList{
                &.active {
                    &:before{
                        background: url(${MedicationDayActive}) no-repeat;
                    }
                    h1{
                        color: #372C65;
                    }
                }
                &.activeSunday{
                    h1{
                        color: #372C65;
                    }
                }

            }
        }
    `};
	${({ waterFluidCard }) =>
		waterFluidCard &&
		`.cardHeader{
            background: #CFEFFF;
            h1{
                color: #103A5B;
            }
            p{
                color: #103A5B;
            }
        }
        .card-body{
            .dayList{
                &.active {
                    &:before{
                        background: url(${WaterDayActive}) no-repeat;
                    }
                    h1{
                        color: #103A5B;
                    }
                }
                &.activeSunday{
                    h1{
                        color: #103A5B;
                    }
                }

            }
        }
    `};
	${({ moodsCard }) =>
		moodsCard &&
		`.cardHeader{
            background: #D8E3FF;
            h1{
                color: #032780;
            }
            p{
                color: #032780;
            }
        }
        .card-body{
            .dayList{
                &.active {
                    &:before{
                        background: url(${MoodsDayActive}) no-repeat;
                    }
                    h1{
                        color: #032780;
                    }
                }
                &.activeSunday{
                    h1{
                        color: #032780;
                    }
                }

            }
        }
    `};
	${({ sleepCard }) =>
		sleepCard &&
		`.cardHeader{
            background: #DFE2EE;
            h1{
                color: #303861;
            }
            p{
                color: #0303861;
            }
        }
        .card-body{
            .dayList{
                &.active {
                    &:before{
                        background: url(${SleepDayActive}) no-repeat;
                    }
                    h1{
                        color: #303861;
                    }
                }
                &.activeSunday{
                    h1{
                        color: #032780;
                    }
                }

            }
        }
    `};
`;

export default Cards;
