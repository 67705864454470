import styled from 'styled-components';

import Image01 from '../../assets/images/wecare-green-logo.svg';

const HeaderLogo = styled.div`
	height: 57px;
	display: flex;
	align-items: center;
	background: url(${Image01});

	background-repeat: no-repeat;
	background-size: cover;
	margin-right: 24px;
	width: 112px;
`;

export default HeaderLogo;
