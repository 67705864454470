import styled from 'styled-components';

const Header = styled.div`
	display: flex;
	width: 100%;
	height: auto;
	background: #0d2954;
	box-shadow: 0px 10px 15px -3px rgba(13, 41, 84, 0.1), 0px 4px 6px -4px rgba(13, 41, 84, 0.1);
	padding: 10px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 999;
	top: 0;
	@media screen and (max-width: 767px) {
		padding-left: 62px;
	}
`;

export default Header;
