import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/storage';
import { ClinicUnits, Roles } from '../helpers/Constants';
// eslint-disable-next-line import/no-cycle
import { getLocalStorageValueAsJSON } from '../pages/Login/login.store';

const firebaseConfig = {
	apiKey: 'AIzaSyBI9eYwZT5Vy6m8yNWzeiOrQhnU6qn1zcw',
	authDomain: 'wecare-camptool.firebaseapp.com',
	projectId: 'wecare-camptool',
	storageBucket: 'wecare-camptool.appspot.com',
	messagingSenderId: '1043417925103',
	appId: '1:1043417925103:web:3dc66c89f3f547ece0e21a',
};

let dataBase = null;
let firebaseAdmin = null;

export const initializeFirebaseApp = async () => {
	await firebase.initializeApp(firebaseConfig);
	firebaseAdmin = await firebase.initializeApp(firebaseConfig, 'Secondary');
	dataBase = await firebase.firestore();
};

export const getFileStorage = (fileName) => firebase.storage().ref(fileName);

export const verifyUserSignIn = async (email, signInLink) => {
	if (await firebase.auth().isSignInWithEmailLink(signInLink)) {
		if (email) {
			firebase
				.auth()
				.signInWithEmailLink(email, signInLink)
				.then(() => true);
		} else {
			return false;
		}
	} else {
		return false;
	}
	//  await firebase
	// 	.auth()
	// 	.isSignInWithEmailLink(signInLink)
	// 	.then(() => {

	// 	});
};

export const sendVerifyEmail = async (email, actionCode) => {
	console.log('EMAIL', email);
	await firebase
		.auth()
		.sendSignInLinkToEmail(email, actionCode)
		.then(() => {
			console.log('Success');
			return true;
		})
		.catch((error) => {
			console.log('ERROR SEND', error);
			return null;
		});
};

export const AuthenticateUser = (credential) =>
	firebase
		.auth()
		.signInWithPhoneNumber(`${credential.country_code}${credential.phone}`, credential.verify)
		.then((userCredential) => userCredential);

export const AuthenticateUserWithEmail = (credential) =>
	firebase
		.auth()
		.signInWithEmailAndPassword(credential.email, credential.password)
		.then((userCredential) => userCredential);

// .catch((error) => error);

export const firebaseAuth = () => firebase;

export const PostData = async (
	collectionName,
	data,
	collectionId = '',
	isSubCollection = false,
	subCollectionName = ''
) => {
	if (isSubCollection) {
		return dataBase
			.collection(collectionName)
			.doc(collectionId)
			.collection(subCollectionName)
			.add(data)
			.then((doc) => doc);
	}
	return dataBase
		.collection(collectionName)
		.add(data)
		.then((doc) => doc);
};

export const Get = async (
	collectionName,
	collectionId = '',
	isSubCollection = false,
	subCollectionName = ''
) => {
	if (isSubCollection) {
		return dataBase
			.collection(collectionName)
			.doc(collectionId)
			.collection(subCollectionName)
			.get()
			.then((querySnapshot) => ({
				data: querySnapshot.data(),
			}));
	}
	return dataBase
		.collection(collectionName)
		.doc(collectionId)
		.get()
		.then((querySnapshot) => ({
			data: querySnapshot.data(),
		}));
};

export const CheckPatientAvailability = async (collectionName, searchParam) => {
	let isDataAvailable = false;
	const clinicId = await getLocalStorageValueAsJSON('clinicId');
	await dataBase
		.collection('Clinics')
		.doc(clinicId)
		.collection(collectionName)
		.where('isActive', '==', true)
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				if (searchParam) {
					const data = doc.data();
					if (data.dob.includes(searchParam.dob) && data.username.includes(searchParam.username)) {
						isDataAvailable = true;
					}
				}
			});
		});

	return { isPatientAvailable: isDataAvailable };
};

export const CheckAdminAvailability = async (clinicId, email = null) => {
	let isDataAvailable = false;
	await dataBase
		.collection('Users')
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				if (email) {
					const data = doc.data();
					if (data.email === email) {
						isDataAvailable = data;
					}
				}
			});
		});

	return { isAdminAvailable: isDataAvailable };
};

export const GetClinicUnit = async () => {
	let clinicUnit = ClinicUnits;
	const clinicId = await getLocalStorageValueAsJSON('clinicId');
	await dataBase
		.collection('Clinics')
		.doc(clinicId)
		.collection('ClinicUnit')
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				clinicUnit = doc.data();
			});
		});
	return clinicUnit;
};

export const GetData = async (collectionName, searchKeyword = '', params = {}) => {
	function compareASC(d1, d2) {
		if (d1.data[params.sortBy] > d2.data[params.sortBy]) {
			return 1;
		}
		if (d1.data[params.sortBy] < d2.data[params.sortBy]) {
			return -1;
		}
		return 0;
	}
	function compareDESC(d1, d2) {
		if (d1.data[params.sortBy] < d2.data[params.sortBy]) {
			return 1;
		}
		if (d1.data[params.sortBy] > d2.data[params.sortBy]) {
			return -1;
		}
		return 0;
	}
	let dataList = [];
	const clinicId = await getLocalStorageValueAsJSON('clinicId');
	await dataBase
		.collection('Clinics')
		.doc(clinicId)
		.collection(collectionName)
		.where('isActive', '==', true)
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				if (searchKeyword.trim().length > 0) {
					const data = doc.data();
					if (
						data.patientUid.toLowerCase().includes(searchKeyword.toLowerCase()) ||
						data.username.toLowerCase().includes(searchKeyword.toLowerCase())
					) {
						dataList.push({ id: doc.id, data });
					}
				} else {
					dataList.push({ id: doc.id, data: doc.data() });
				}
			});
		});

	dataList = await dataList.sort((d1, d2) => d1.data.createdAt - d2.data.createdAt);
	if (params.sortBy) {
		if (params.order === 'asc') {
			dataList = await dataList.sort(compareASC);
		} else {
			dataList = await dataList.sort(compareDESC);
		}
	}
	if (params.gender && params.gender !== 'all') {
		dataList = await dataList.filter((d1) => d1.data.gender === params.gender);
	}
	return dataList;
};

export const PutData = (
	collectionName,
	collectionId,
	data,
	isSubCollection = false,
	subCollectionName = '',
	subCollectionId = ''
) => {
	if (isSubCollection) {
		return dataBase
			.collection(collectionName)
			.doc(collectionId)
			.collection(subCollectionName)
			.doc(subCollectionId)
			.set(data);
	}
	return dataBase.collection(collectionName).doc(collectionId).set(data);
};

export const DeleteDataById = (collectionName, collectionId) =>
	dataBase.collection(collectionName).doc(collectionId).delete();

export const GetUserList = async (role, defaultSort = null, searchKeyword = '') => {
	const dataList = [];
	await dataBase
		.collection('Users')
		.where('role', '==', role)
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				const data = doc.data();
				data.id = doc.id;
				if (searchKeyword.trim().length > 0) {
					if (
						data.clinicUid.toLowerCase().includes(searchKeyword.toLowerCase()) ||
						data.name.toLowerCase().includes(searchKeyword.toLowerCase())
					) {
						dataList.push({ id: doc.id, data });
					}
				} else {
					dataList.push({ id: doc.id, data });
				}
			});
		});
	if (defaultSort) await dataList.sort((d1, d2) => d1.data[defaultSort] - d2.data[defaultSort]);
	return dataList;
};

export const GetList = async (collectionName, defaultSort = null, searchKeyword = '') => {
	const dataList = [];
	await dataBase
		.collection(collectionName)
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				const data = doc.data();
				data.id = doc.id;
				if (searchKeyword.trim().length > 0) {
					if (
						data.clinicUid.toLowerCase().includes(searchKeyword.toLowerCase()) ||
						data.name.toLowerCase().includes(searchKeyword.toLowerCase())
					) {
						dataList.push({ id: doc.id, data });
					}
				} else {
					dataList.push({ id: doc.id, data });
				}
			});
		});
	if (defaultSort) await dataList.sort((d1, d2) => d1.data[defaultSort] - d2.data[defaultSort]);
	return dataList;
};

export const GetClinicDataById = async (collectionName, collectionId) => {
	const baseData = await dataBase
		.collection(collectionName)
		.doc(collectionId)
		.get()
		.then((querySnapshot) => ({
			data: querySnapshot.data(),
		}));
	baseData.data.id = collectionId;
	const clinicAdmins = [];
	await dataBase
		.collection(collectionName)
		.doc(collectionId)
		.collection('ClinicAdmins')
		.where('isActive', '==', true)
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				const data = doc.data();
				data.id = doc.id;
				clinicAdmins.push(data);
			});
		});
	await clinicAdmins.sort((d1, d2) => d1.createdAt - d2.createdAt);
	baseData.data.clinicAdmins = clinicAdmins;
	return baseData;
};

export const CreateUser = (data) =>
	firebaseAdmin
		.auth()
		.createUserWithEmailAndPassword(data.email, data.password)
		.then((userCredential) => {
			const { user = {} } = userCredential;
			if (user.uid) {
				data.uid = user.uid;
				data.isActive = true;
				PostData('Users', data).then((newUser) => {
					data.uid = user.uid;
					data.userId = newUser.id;
					PostData('Clinics', data, data.clinicID, true, 'ClinicAdmins');
				});
			} else {
				console.log('NO USER_UID', userCredential);
			}
		})
		.catch((error) => error);

export const GetAuthenticatedUser = async (collectionName, uid) => {
	const baseData = await dataBase
		.collection(collectionName)
		.where('uid', '==', uid)
		.get()
		.then((querySnapshot) => {
			if (querySnapshot.empty) {
				return {
					data: {
						role: Roles.weCareAdmin,
						name: 'Super admin',
						isActive: true,
					},
				};
			}
			const data = querySnapshot.docs[0].data();
			data.userId = querySnapshot.docs[0].id;
			return { data };
		});
	return baseData;
};

export const VerifyPhone = async (collectionName, phone, countryCode) => {
	const baseData = await dataBase
		.collection(collectionName)
		.where('phone', '==', phone)
		.where('country_code', '==', countryCode)
		.get()
		.then((querySnapshot) => {
			console.log('QSNAP', querySnapshot);
			if (querySnapshot.empty) {
				return {
					data: null,
				};
			}
			const data = querySnapshot.docs[0].data();
			data.userId = querySnapshot.docs[0].id;
			return { data };
		});
	return baseData;
};

export const VerifyEmailOTP = async (collectionName, email, otp) => {
	const baseData = await dataBase
		.collection(collectionName)
		.where('email', '==', email)
		.where('default_otp', '==', otp)
		.get()
		.then((querySnapshot) => {
			if (querySnapshot.empty) {
				return {
					data: null,
				};
			}
			const data = querySnapshot.docs[0].data();
			data.userId = querySnapshot.docs[0].id;
			return { data };
		});
	return baseData;
};

export const VerifyLoginEmail = async (collectionName, email) => {
	const baseData = await dataBase
		.collection(collectionName)
		.where('email', '==', email)
		.get()
		.then((querySnapshot) => {
			if (querySnapshot.empty) {
				return {
					data: null,
				};
			}
			const data = querySnapshot.docs[0].data();
			data.userId = querySnapshot.docs[0].id;
			return { data };
		});
	return baseData;
};

export const VerifyLoginPhone = async (collectionName, phone, countryCode) => {
	const baseData = await dataBase
		.collection(collectionName)
		.where('phone', '==', phone)
		.where('country_code', '==', countryCode)
		.get()
		.then((querySnapshot) => {
			if (querySnapshot.empty) {
				return {
					data: null,
				};
			}
			const data = querySnapshot.docs[0].data();
			data.userId = querySnapshot.docs[0].id;
			return { data };
		});
	return baseData;
};

export const VerifyLoginPassword = async (collectionName, email, password) => {
	const baseData = await dataBase
		.collection(collectionName)
		.where('email', '==', email)
		.where('password', '==', password)
		.get()
		.then((querySnapshot) => {
			if (querySnapshot.empty) {
				return {
					data: null,
				};
			}
			const data = querySnapshot.docs[0].data();
			data.userId = querySnapshot.docs[0].id;
			return { data };
		});
	return baseData;
};

export const VerifyEmail = async (collectionName, email) => {
	const baseData = await dataBase
		.collection(collectionName)
		.where('email', '==', email)
		.get()
		.then((querySnapshot) => {
			if (querySnapshot.empty) {
				return {
					data: null,
				};
			}
			const data = querySnapshot.docs[0].data();
			data.userId = querySnapshot.docs[0].id;
			return { data };
		});
	return baseData;
};

const deleteAdmin = async (clinicId, docId) => {
	dataBase.collection('Clinics').doc(clinicId).collection('ClinicAdmins').doc(docId).delete();
};

export const DeactivateUser = (data) =>
	PutData('Users', data.userId, data).then(() => {
		deleteAdmin(data.clinicID, data.id);
	});

export const activateUser = (data) =>
	PutData('Users', data.userId, data).then(() => {
		PostData('Clinics', data, data.clinicID, true, 'ClinicAdmins');
	});
