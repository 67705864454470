import ExerciseIconSVG from '../stories/assets/images/icons/card-exercise-icon.svg';
import DietIconSVG from '../stories/assets/images/icons/card-diet-icon.svg';
import MedicationIconSVG from '../stories/assets/images/icons/card-medication-icon.svg';
import FluidIntakeIconSVG from '../stories/assets/images/icons/card-fluid-icon.svg';
import MoodIconSVG from '../stories/assets/images/icons/card-smile-icon.svg';
import SleepIconSVG from '../stories/assets/images/icons/card-sleep-icon.svg';

import ExerciseHeaderIconSVG from '../stories/assets/images/icons/activity-exercise-icon.svg';
import DietHeaderIconSVG from '../stories/assets/images/icons/activity-diet-icon.svg';
import MedicationHeaderIconSVG from '../stories/assets/images/icons/activity-medication-icon.svg';
import FluidHeaderIconSVG from '../stories/assets/images/icons/activity-fluid-icon.svg';
import MoodHeaderIconSVG from '../stories/assets/images/icons/activity-mood-icon.svg';
import SleepHeaderIconSVG from '../stories/assets/images/icons/activity-sleep-icon.svg';

import IndianFlag from '../assets/images/Indian-Flag.png';
import MyanmarFlag from '../assets/images/Myanmar-Flag.png';
import EthiopiaFlag from '../assets/images/Ethiopia-Flag.png';
import NigeriaFlag from '../assets/images/Nigeria-Flag.png';

export const MILLISECONDS_IN_A_DAY = 86400000;

export const MAXIMUM_UPLOAD_FILE_SIZE = 5000000;

export const MAXIMUM_CHARACTERS_ALLOWED = 10000;

export const MAXIMUM_MESSAGE = 30;

export const PER_PAGE = 10;

export const QuestionType = {
	SINGLE_CHOICE: { label: 'Single choice', value: 'single_choice' },
	SINGLE_LINE: { label: 'Short text', value: 'single_line' },
	SECTION: { label: 'Section', value: 'section' },
	MULTI_LINE: { label: 'Description', value: 'multi_line' },
	MULTI_CHOICE: { label: 'Multiple choice', value: 'multi_choice' },
	DROP_DOWN: { label: 'Dropdown', value: 'drop_down' },
};

export const DiagnoseStatus = [
	{ label: 'Previously diagnosed', value: 'Previously diagnosed' },
	{ label: 'Newly diagnosed', value: 'Newly diagnosed' },
];

export const MONTHS_IN_A_YEAR = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

export const Pattern = {
	EMAIL:
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	// NAME: /^[a-z\d.'\s]+$/,
	NAME: /^.*/,
};

export const dateSeparatorConfig = {
	chat: 'T_D_DMY', // Example: 03:15 PM/YESTERDAY/01 Apr 2020
	hh_mm_a: 'hh_mm_a', // Example: 03:15 PM
	L_D_M_Y: 'LDMY', // Example: 1 April 2020
	l_d_m_y: 'ldmy', // Example: 01 Apr 2020
	hh_mm: 'hh_mm', // Example: 03:15
	default: 'default', // Example: 01 Apr 2020, 03:15 PM
	defaultDate: 'defaultDate', // Example: 01 Apr 2020
	defaultTime: 'defaultTime', // Example: 03:15 PM
	defaultRecentDate: 'defaultRecentDate', // Example: Today OR Yesterday OR 01 Apr 2020
	defaultRecentDatetime: 'defaultRecentDatetime', // Example: Today, 03:15 PM OR Yesterday, 03:15 PM OR 01 Apr 2020, 03:15 PM
	yyyy_mm_dd: 'yyyy_mm_dd', // Example: 2020-02-29
	d_m: 'd_m', // 05 Apr
};

export const APP_TOKEN = 'APP_TOKEN';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const UID = 'UID';

export const FCM_TOKEN = 'TOKEN';

export const Roles = {
	coach: 'Coach',
	doctor: 'Doctor',
	weCareAdmin: 'WeCareAdmin',
	clinicAdmin: 'ClinicAdmin',
	all: 'All',
	CM: 'CM',
	FA: 'FA',
};

export const SurveyStatus = {
	pending: 'Pending',
	inProgress: 'In progress',
	completed: 'Completed',
};

export const SurveyNext = {
	pending: 'Start survey',
	started: 'Continue',
	completed: 'Submitted',
};

export const FileExtension = [
	'jpg',
	'jpeg',
	'bmp',
	'png',
	'docx',
	'doc',
	'pdf',
	'xls',
	'xlsx',
	'xlsm',
	'txt',
];
export const ImgExtension = ['jpg', 'jpeg', 'bmp', 'png'];
// Here, the "Coach" key has to be same as the "Roles.coach" (above) value
export const RolesFromApi = {
	doctor: 'doctor',
	clinicAdmin: 'clinic-admin',
	Coach: 'coach',
};

export const BP = 'BP';

export const NIL = '---';

export const PENDING_SIGN_UP = 'Pending sign-up';

export const vitalKeys = {
	systolic: 'SYSTOLIC',
	diastolic: 'DIASTOLIC',
	temperature: 'TEMPERATURE',
	heartRate: 'HEART_RATE',
	respiratoryRate: 'RESPIRATORY_RATE',
	oxygenSaturation: 'OXYGEN_SATURATION',
};

export const snackBarErrorTypes = {
	info: 'info',
	error: 'Error',
	success: 'Success',
	warning: 'Warning',
};

export const doctorLabel = 'Dr. ';

export default {
	patientVault: {
		details: '#details',
		health: '#healthvault',
		clinicalNotes: '#clinicalnotes',
		clinicalDocs: '#clinicaldocs',
		sleep: '#sleep',
		mood: '#mood',
		vitals: '#vitals',
		lab: '#labs',
		abnormality: '#abnormality',
		weights: '#weights',
		vitalDetails: '#vitaldetails',
		labDetails: '#labdetails',
		activityJournal: '#activityjournal',
		visitSummary: '#visitSummary',
	},
};

export const Activity = {
	EXERCISE: {
		label: 'Exercise',
		icon: ExerciseIconSVG,
		className: {},
		node: 'exercise',
		activityDate: 'activityDate',
		headerIcon: ExerciseHeaderIconSVG,
		title: 'Active',
		titles: 'Active',
		subTitle: 'days',
	},
	DIET: {
		label: 'Diet',
		icon: DietIconSVG,
		className: { dietCard: true },
		node: 'diet',
		activityDate: 'intakeDate',
		headerIcon: DietHeaderIconSVG,
		title: 'day',
		titles: 'days',
		subTitle: 'on diet plan',
	},
	MEDICATIONS: {
		label: 'Medication',
		icon: MedicationIconSVG,
		className: { medicationCard: true },
		node: 'medication',
		activityDate: 'intakeDate',
		headerIcon: MedicationHeaderIconSVG,
		title: 'day',
		titles: 'days',
		subTitle: 'on medication',
	},
	FLUID_INTAKE: {
		label: 'Water and fluid intake',
		icon: FluidIntakeIconSVG,
		className: { waterFluidCard: true },
		node: 'fluid',
		activityDate: 'intakeDate',
		headerIcon: FluidHeaderIconSVG,
		title: '',
		titles: '',
		subTitle: 'Glasses',
	},
	MOOD: {
		label: 'Moods',
		icon: MoodIconSVG,
		className: { moodsCard: true },
		node: 'moods',
		activityDate: 'date',
		headerIcon: MoodHeaderIconSVG,
		title: 'day',
		titles: 'days',
		subTitle: 'logged',
	},
	SLEEP: {
		label: 'Sleep',
		icon: SleepIconSVG,
		className: { sleepCard: true },
		node: 'sleep',
		activityDate: 'date',
		headerIcon: SleepHeaderIconSVG,
		title: 'day',
		titles: 'days',
		subTitle: 'logged',
	},
};

export const Time = {
	'MINS_60+': '1+ hours',
	MINS_15: '15 mins',
	MINS_45: '45 mins',
	MINS_30: '30 mins',
	MINS_60: '1 hour',
};

export const Intensity = {
	HARD: { text: 'Hard', class: { Hard: true } },
	MODERATE: { text: 'Moderate', class: { Moderate: true } },
	EASY: { text: 'Easy', class: { Easy: true } },
};

export const ActivityList = {
	OTHERS: 'Others',
	WALK_RUN: 'Walk/Run',
	WORKOUT: 'Workout',
	YOGA: 'Yoga',
	SWIMMING: 'Swimming',
	GAME: 'Game',
	MISC: 'Misc',
	CYCLING: 'Cycling',
	INACTIVE: 'Inactive',
};

export const Days = {
	SUN: 'Sun',
	MON: 'Mon',
	TUE: 'Tue',
	WED: 'Wed',
	THU: 'Thu',
	FRI: 'Fri',
	SAT: 'Sat',
};

export const CountryList = [
	{
		code: '+91',
		flag: IndianFlag,
	},
	{
		code: '+95',
		flag: MyanmarFlag,
	},
	{
		code: '+251',
		flag: EthiopiaFlag,
	},
	{
		code: '+234',
		flag: NigeriaFlag,
	},
];

export const BloodGroupList = ['A+ve', 'A-ve', 'B+ve', 'B-ve', 'O+ve', 'O-ve', 'AB+ve', 'AB-ve'];
export const SecondaryHealthConditionList = [
	'Hypertension',
	'Arthritis',
	'Asthma',
	'Osteoporosis',
	'Not applicable',
];

export const DiabeticList = [
	'Type 1 diabetes',
	'Type 2 diabetes',
	'Gestational diabetes',
	'Not sure what type',
	"No , I'm not",
	"I'm not sure",
	"No , I'm not",
];

export const HobbiesList = [
	'Travel',
	'Art',
	'Cooking',
	'Reading',
	'Sports',
	'Singing',
	'Dancing',
	'Not applicable',
];

export const PhysicallyActiveList = ['Active', 'Bedridden', 'Moderate', 'Sedentary'];

export const AllergiesList = ['Food', 'Dust', 'Environment', 'Medicine'];

export const AlcoholHabitList = ['Yes', 'No', 'Occasionally'];

export const LabReportList = [
	{
		category: 'GLUCOSE',
		name: 'Glucose',
		tests: [
			{
				lookupKey: 'URINE_SUGAR',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'HBA1C',
				unitType: '%',
				description: '',
			},
			{
				lookupKey: 'GTT',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'PPBS',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'RBS',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'FBS',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'GRBS',
				unitType: 'mg/dL',
				description: '',
			},
		],
	},
	{
		category: 'CHOLESTEROL',
		name: 'Cholesterol',
		tests: [
			{
				lookupKey: 'VLDL',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'TOTAL_CHOLESTEROL',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'TRIGLYCERIDES',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'HDL',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'LDL',
				unitType: 'mg/dL',
				description: '',
			},
		],
	},
	{
		category: 'KFT',
		name: 'KFT',
		tests: [
			{
				lookupKey: 'SERUM_URIC_ACID',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'SERUM_CREATININE',
				unitType: 'µmol/L',
				description: '',
			},
			{
				lookupKey: 'BLOOD_UREA',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'TFT',
		name: 'TFT',
		tests: [
			{
				lookupKey: 'FREE_T4',
				unitType: 'pmol/L',
				description: '',
			},
			{
				lookupKey: 'FREE_T3',
				unitType: 'ng/L',
				description: '',
			},
			{
				lookupKey: 'T4',
				unitType: 'nmol/L',
				description: '',
			},
			{
				lookupKey: 'T3',
				unitType: 'nmol/L',
				description: '',
			},
			{
				lookupKey: 'TSH',
				unitType: 'µIU/mL',
				description: '',
			},
		],
	},
	{
		category: 'LFT',
		name: 'LFT',
		tests: [
			{
				lookupKey: 'ALKALINE',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'GAMMA_GT',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'ALT',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'AST',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'AG_RATIO',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'UNCONJ_BILIRUBIN',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'CONJ_BILIRUBIN',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'TOTAL_BILIRUBIN',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'HEMATOLOGY',
		name: 'Hematology',
		tests: [
			{
				lookupKey: 'ESR',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'PLATELETS',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'TC',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'TD',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'HB',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'URINE',
		name: 'Urine',
		tests: [
			{
				lookupKey: 'URINE_PREGNANCY',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'URINE_CULTURE',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'URINE_ROUTINE',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'STOOL',
		name: 'Stool',
		tests: [
			{
				lookupKey: 'STOOL_OCCULT',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'STOOL_CULTURE',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'STOOL_ROUTINE',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'SPUTUM',
		name: 'Sputum',
		tests: [
			{
				lookupKey: 'SPUTUM_CULTURE',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'CSF',
		name: 'CSF',
		tests: [
			{
				lookupKey: 'CSF_CULTURE',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'CSF_ROUTINE',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'ASCITIC',
		name: 'Ascitic fluid for counts',
		tests: [
			{
				lookupKey: 'ASCITIC_MALIGNANT',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'ASCITIC_CULTURE',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'PLEURAL',
		name: 'Pleural',
		tests: [
			{
				lookupKey: 'PLEURAL_MALIGNANT',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'PLEURAL_CULTURE',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'PLEURAL_COUNTS',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'PUS',
		name: 'Pus',
		tests: [
			{
				lookupKey: 'PUS_CULTURE',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'GRAMS_STAIN',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'OTHERS',
		name: 'Others',
		tests: [
			{
				lookupKey: 'OTHERS',
				unitType: '',
				description: '',
			},
		],
	},
];

export const VitalNormalcy = {
	SYSTOLIC: {
		min: 90,
		max: 130,
		name: 'Systolic',
	},

	DIASTOLIC: {
		min: 60,
		max: 90,
		name: 'Diastolic',
	},

	HEART_RATE: {
		min: 50,
		max: 100,
		name: 'Heart rate',
	},

	TEMPERATURE: {
		fahrenheit: { min: 91.4, max: 100 },
		celsius: { min: 33.0, max: 37.7 },
		name: 'Temperature',
	},
	OXYGEN_SATURATION: {
		min: 98,
		max: 100,
		name: 'Oxygen saturation',
	},
	RESPIRATORY_RATE: {
		min: 10,
		max: 25,
		name: 'Respiratory rate',
	},
};

export const ClinicUnits = {
	height: 'cm',
	weight: 'kg',
	waist: 'cm',
	hip: 'cm',
	HEART_RATE: 'bpm',
	SYSTOLIC: 'mmHg',
	DIASTOLIC: 'mmHg',
	RESPIRATORY_RATE: 'mt',
	OXYGEN_SATURATION: '%',
	URINE_SUGAR: 'mg/dL',
	HBA1C: '%',
	GTT: 'mg/dL',
	PPBS: 'mg/dL',
	RBS: 'mg/dL',
	FBS: 'mg/dL',
	GRBS: 'mg/dL',
	VLDL: 'mg/dL',
	TOTAL_CHOLESTEROL: 'mg/dL',
	TRIGLYCERIDES: 'mg/dL',
	HDL: 'mg/dL',
	LDL: 'mg/dL',
	SERUM_URIC_ACID: '',
	SERUM_CREATININE: 'µmol/L',
	BLOOD_UREA: '',
	FREE_T4: 'pmol/L',
	FREE_T3: 'ng/L',
	T4: 'nmol/L',
	T3: 'nmol/L',
	TSH: 'µIU/mL',
	ALKALINE: '',
	GAMMA_GT: '',
	ALT: '',
	AST: '',
	AG_RATIO: '',
	UNCONJ_BILIRUBIN: '',
	CONJ_BILIRUBIN: '',
	TOTAL_BILIRUBIN: '',
	ESR: '',
	PLATELETS: '',
	TC: '',
	TD: '',
	HB: '',
	URINE_PREGNANCY: '',
	URINE_CULTURE: '',
	URINE_ROUTINE: '',
	STOOL_OCCULT: '',
	STOOL_CULTURE: '',
	STOOL_ROUTINE: '',
	SPUTUM_CULTURE: '',
	CSF_CULTURE: '',
	CSF_ROUTINE: '',
	ASCITIC_MALIGNANT: '',
	ASCITIC_CULTURE: '',
	PLEURAL_MALIGNANT: '',
	PLEURAL_CULTURE: '',
	PLEURAL_COUNTS: '',
	PUS_CULTURE: '',
	GRAMS_STAIN: '',
};
