import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List } from '../../stories';
import { clearToast } from './toast.store';
import ToastMessage from '../ToastMessage';

export default function Toast() {
	const dispatch = useDispatch();
	const [toast, setToast] = useState(false);
	let timeouts = null;
	const { isMessage, messageType, messageContent } = useSelector((state) => {
		const {
			statusCode = 0,
			isMessage = false,
			messageType = '',
			messageContent = {},
		} = state.toastStore;
		return {
			statusCode,
			isMessage,
			messageType,
			messageContent,
		};
	});

	function toastTimeOut() {
		timeouts = setTimeout(() => {
			setToast(false);
			dispatch(clearToast());
			timeouts = null;
		}, 3000);
	}

	useEffect(() => {
		if (isMessage && messageContent !== '') {
			setToast(true);
			toastTimeOut();
		}
		return () => {
			clearTimeout(timeouts);
		};
	}, [isMessage]);

	const closeToast = () => {
		dispatch(clearToast());
		setToast(false);
	};

	function errorList(messageContent) {
		const validation =
			Object.keys(messageContent).length === 0 ? (
				<li>something went wrong</li>
			) : (
				Object.keys(messageContent).map((e, i) => {
					const { message = '' } = messageContent[e];
					return <li key={`e${i}`}>{message}</li>;
				})
			);
		return <List>{validation}</List>;
	}

	return (
		<>
			{toast && (
				<ToastMessage
					openToast={toast}
					messageType={messageType}
					content={errorList(messageContent)}
					closeToast={closeToast}
				/>
			)}
		</>
	);
}
